<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
  			<el-breadcrumb-item>基本信息</el-breadcrumb-item>
		</el-breadcrumb>
        <div class="info-box">
            <div class="top">
                <el-alert title="客服热线：0536-8880726" type="warning" :closable="false"></el-alert>
            </div>
            <div class="middle">
                <span class="title">账号:{{pageform.passport}}</span>
                <span class="account"></span>
            </div>
            <div class="content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm authentication-form">
					<el-form-item label="手机号">
					    <el-input style="width:40%" v-model="pageform.phone" :disabled="this.pageform.phone != '' && this.pageform.phone != 'null'"></el-input>
					</el-form-item>
					<el-form-item label="图形验证码">
						<div style="display: flex;">
							<el-input v-model="verifyCode" style="width:40%"></el-input>
							<div>
								<img class="codeimg" style="width: 120px; height: 40px;margin-left: 10px;" :src="verifyCodeImg" @click="handleRefresh()">
							</div>
						</div>
					</el-form-item>
					<el-form-item label="图形验证码(隐藏)" style="display: none;">
					    <el-input v-model="verifyKey" style="width:40%"></el-input>
					</el-form-item>
					<el-form-item label="手机验证码">
					    <el-input v-model="formData.sms" style="width:40%" placeholder="请输入手机验证码"></el-input>
						<el-button type="primary" style="margin-left: 10px;" v-if="showbtn" @click="SendMessage()">获取验证码</el-button>
						<el-button type="primary" v-else style="margin-left: 10px;":disabled="attcode">{{code_ts}}</el-button>
						
					</el-form-item>
					<el-form-item label="开户行">
					    <el-input v-model="pageform.payBank" style="width:40%"></el-input>
					</el-form-item>
					<el-form-item label="银行卡号">
					    <el-input v-model="pageform.payCardno" style="width:40%"></el-input>
					</el-form-item>
					<el-form-item label="收款码">
						<div class="money_code">
							<img v-if="this.payWeixin !=''" width="88px" :src="'https://file.aleopool.top/' + this.payWeixin" alt="" style="margin-right: 10px;">
							<div v-else style="margin-right: 10px;">
								<el-upload
								:limit="1"
								:on-success="uploadVX"
								:action="uploadUrl"
								:data="postData"
								list-type="picture-card"
								:src="payWeixin"
								:on-remove="handleRemoveVX"
								:on-change="handleChangeVX"
								:class="{hidefront:hidefrontUploadCard}"
								>
								<i class="el-icon-plus"></i>
								</el-upload>
							</div>
							<img v-if="this.payZhifubao !=''" width="80px" :src="'https://file.aleopool.top/' + this.payZhifubao" alt="" @click ="showZFB()">
							<div v-else>
								<el-upload
								:limit="1"
								:on-success="uploadZFB"
								:action="uploadUrl"
								:data="postData"
								list-type="picture-card"
								:src="payZhifubao"
								:on-remove="handleRemoveZFB"
								:on-change="handleChangeZFB"
								:class="{hideback:hidebackUploadCard}"
								>
								<i class="el-icon-plus"></i>
								</el-upload>
							</div>
						</div>
					    
					</el-form-item>
					<el-form-item label="aleo钱包地址">
					    <el-input v-model="pageform.aleoAddress" style="width:40%"></el-input>
					</el-form-item>
					<div class="btn_bind">
						<el-button type="primary" @click="handleSafe()">绑定</el-button>
					</div>
					
                </el-form>
            </div>
        </div>
	</div>	
</template>

<script>
import { Personal,Admin} from "@/api/index";
  export default {
    data() {
      return {
		hidebackUploadCard: false,
		hidefrontUploadCard: false,
		confirm: true,  //提交验证按钮判断
		attcode: false,  //点击获取验证码按钮判断
		showbtn: true, // 展示获取验证码或倒计时按钮判断
		yzcode: '',
		code_ts: '获取验证码', //倒计时提示文字
		sec: 60 ,// 倒计时秒数
		submitForm: {
			mobile:'',
		},
		rules: {
		  wallet: [
		    { required: true, message: '请填写地址', trigger: 'blur' },
		  ],
		  mobile: [
		    { required: true, message: '请输入手机号', trigger: 'blur' },
		  ],
		  code: [
		    { required: true, message: '请输入验证码', trigger: 'blur' },
		  ],
		},
		form:{
			nums:'',
		},
        ruleForm: {},
		pageform:{
			
		},
		//验证码表单
		formData:{
			sms:'',
			phone:'',
		},
		//图形验证码
		verifyCode:'',
		verifyKey:'',
		verifyCodeImg:'',
		payWeixin:'',
		payZhifubao:'',
        rules: {},
        no_bind:false,
		alter:false,
		copy:false,
		images:[],
      };
    },
	created() {
		this.Info();
		this.handleCaptcha();
		if(this.pageform.phone !='' && this.payBank != '' && this.payCardno != '' && this.aleoAddress != '' && this.payWeixin != '' && this.payZhifubao != '' ) {
			this.bindbtn = false;
		}else {
			this.bindbtn = true;
		}
	},
	methods:{
		async Info() {
				let { status, data } = await Personal.info(); 
				if (status) {
					if(data.code == 0) {
						this.pageform = data.data;
						this.payCardno = this.pageform.payCardno;
						this.payBank = this.pageform.payBank;
						this.payWeixin = this.pageform.payWeixin;
						this.payZhifubao = this.pageform.payZhifubao;
						this.aleoAddress = this.pageform.aleoAddress;
					}
				}
			},
			//获取图形验证码
			async handleCaptcha(values) {
				let { status, data } = await Admin.verifyCode();
				if (status) {
					if (data.code === 0) {
						this.verifyCodeImg = data.data.img
						this.verifyKey = data.data.key
					} 
				} else {
					this.$message.error("data.message")
				}
			},
			//点击图片重新获取验证码
			handleRefresh() {
				this.handleCaptcha();
			},
			//手机验证码
			async SendMessage() {
				if(this.verifyCode=='' && this.verifyKey ==''&& this.info.phone=='') {
					this.$message.error('请先填写手机号或图形验证码');
					return;
				}
				let {
					status,
					data
				} = await Admin.sendsms({
					Mobile:this.pageform.phone,
					VerifyCode:this.verifyCode,
					VerifyKey:this.verifyKey,
				});
				if (status) {
					if (data.code == 0) {
						var timer = setInterval(() => {
							this.sec = this.sec - 1
							this.code_ts = this.sec + 'S后重试'
							this.showbtn = false
							this.attcode = true
							if (this.sec === 0) {
								clearInterval(timer)
								this.sec = 60
								this.code_ts = this.sec + 'S后重试'
								this.showbtn = true
								this.attcode = false
							}
						}, 1000)
						this.$message.success('发送成功');
					} else {
						this.$message.error(data.message);
					}
				}
			},
		//绑定安全信息
		async handleSafe() {
			if(this.verifyCode=='' && this.verifyKey ==''&& this.pageform.phone==''&& this.pageform.aleoAddress=='') {
				this.$message.error('请先填写以上信息');
				return;
			}
			let {
				status,
				data
			} = await Admin.security({
				phone:this.pageform.phone,
				sms:this.formData.sms,
				aleoAddress:this.pageform.aleoAddress,
				payWeixin:this.payWeixin,
				payZhifubao:this.payZhifubao,
				payCardno:this.pageform.payCardno,
				payBank:this.pageform.payBank,
			});
			if (status) {
				if (data.code == 0) {
					this.$message.success('操作成功');
					this.$router.go(0)
				} else {
					this.$message.error(data.message);
				}
			}
		},
		
		onCopy(e){ 　　 // 复制成功
			this.$message({
				message:'复制成功！',
				type:'success'
			})
		},
		onError(e){　　 // 复制失败
			this.$message({
				message:'复制失败！',
				type:'error'
			})
		},
		handleRemoveVX(file, fileList) {
			this.hidefrontUploadCard = fileList.length >= 1;
			let i = file.response.data
			this.images = this.images.filter(item => item != i)
		},
		handleRemoveZFB(file, fileList) {
			this.hidebackUploadCard = fileList.length >= 1;
			let i = file.response.data
			this.images = this.images.filter(item => item != i)
		},
		handleChangeVX (file,fileList){
			this.hidefrontUploadCard = fileList.length >= 1;
		},
		handleChangeZFB (file,fileList){
			this.hidebackUploadCard = fileList.length >= 1;
		},
		uploadVX(response, file, fileList){
		    this.payWeixin = response.data.path
		},
		uploadZFB(response){
		    this.payZhifubao = response.data.path
		},
	},
    computed:{
	   uploadUrl() {
	   	return this.$base_url + 'api/v1/upload/payment'
	   },
	   postData(){
	       return {token:localStorage.getItem('token')}
	   }
   }
  }
</script>

<style lang="less">
    body {
		margin: 0;
		padding: 0;
		background-color: #F0F3FA;
	}
	.el-breadcrumb{
		padding: 30px;
		.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
			color: #999999;
			font-weight: 500;
		}
		.el-breadcrumb__item:last-child .el-breadcrumb__inner {
			color: #1493FA;
		}
    }
    .info-box {
        height: 933px;
        margin: 0 20px;
        background-color: #ffffff;
        .top {
            height: 93px;
            background-color: #fff;
            display: flex;
            align-items: center;
			margin: 0 25px;
            .el-alert--warning.is-light {
                height: 48px;
            }
        }
        .middle {
            height: 58px;
            background-color: #F8F8F8;
            padding-top: 15px;
            padding-left: 30px;
            box-sizing: border-box;
            .title {
                font-size: 20px;
				color: #333333;
				font-weight: 600;
				border-left: 3px solid #1493FA;
                padding-left: 10px;
                margin-top: 20px;
            }
            .account {
                margin-left: 40px;
                color: #0486FE;
                font-size: 18px;
            }
        }
        .content {
			.btn_bind {
				width: 50%;
				display: flex;
				justify-content: center;
			}
            .authentication-form {
                margin-top: 39px;
                .el-form-item__content {
                    margin-left: 130px!important;
                }
                .el-form-item {
                    margin-left: 30px;
                }
            }
            .money_code {
				display: flex;
				align-items: center;
			}
        }
		.hidefront .el-upload--picture-card {
		  display: none;
		}
		.hideback .el-upload--picture-card {
		  display: none;
		}
        .fixed-bottom {
            position: fixed;
            bottom: 0;
            right: 38px;
            width: 86.5%;
            height: 75px;
            background-color: #fff;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }
</style>